import styled from "@emotion/styled";
import { Dropdown } from "react-bootstrap";

export const CaptionWrapper = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  border-bottom: 0.125rem dashed rgb(225, 225, 225);
  border-radius: 0px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition: 0.25s;
  &:not(:first-of-type) {
  padding-top: 1rem;
  }

  .caption-text {
    width: 100%;
    border: none;
    font-family: Poppins, sans-serif;
    color: grey;
    background: none;
    margin-top: 0px;
    transition: 0.25s;
    font-size: 14px;
    display: block;
    padding: 0px
    &:focus {
      outline: none !important;
      border: 2px solid #2f8de4;
      border-radius: 0.5rem;
      box-shadow: none;
      padding: 5px 10px;
    }
    &.placeholder::before {
      content: "Skriv inn tekst her..";
      color: grey;
      display: block;
    }
  }

  .add-button{
    display: none;
  }


  &:hover {
    cursor: pointer;
    .add-button{
      display: initial;
    }
  }
  @media (max-width: 975px) {
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

export const InnerCaptionWrapper = styled.div`
  padding: 8px 10px;
  border-radius: 0.5rem;
  &.active {
    background-color: #2f8de4 !important;
    .caption-text,
    .bind-icon,
    input,
    button {
      color: #fff;
    }
  }
`;

export const UpperRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const UpperCol = styled.div`
  &.delete {
    max-width: 70px;
  }
  &.left-aligned {
    text-align: left;
    display: flex;
  }
  &.right-aligned {
    text-align: right;
    button {
      padding-right: 0px;
    }
  }
`;

export const TimestampInput = styled.input`
  border: none;
  background: transparent;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  width: 107.5px;
  height: 20px;
  &:focus {
    outline: none !important;
    border: 2px solid #2f8de4;
    border-radius: 0.5rem;
    box-shadow: none;
  }
`;

export const BindingIcon = styled.span`
  display: inline;
  margin-left: 0px;
  margin-right: 15px;
  color: grey;
  margin-top: -2.5px;
`;

export const DropdownToggle = styled(Dropdown.Toggle)`
  padding: 0px;
  background: transparent !important;
  border: none;
  &::after {
    display: none;
  }
  &.show {
    background: none;
  }
  &:hover {
    background: #fff;
  }
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  min-width: 10.75rem;
  box-shadow: 0rem 0rem 0.9375rem #d6d6d6;
  border: none;
  margin-top: 0.3125rem;
  padding: 0.9375rem;
`;

export const MenuButton = styled.button`
  border: none;
  background: none;
  font-size: 15px;
  color: grey;
  transition: 0.25s;
`;

export const AddButton = styled.button`
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 25px; /* Set a fixed width */
  height: 25px; /* Set the same height to make it a perfect circle */
  border-radius: 50%; /* Use 50% to ensure the button is round */
  background-color: #fff;
  color: grey;
  border: 0.125rem dashed rgb(225, 225, 225);
  font-size: 12px;
  display: flex; /* Center the content */
  align-items: center; /* Align content vertically */
  justify-content: center; /* Align content horizontally */
  padding: 0; /* Remove any default padding */

  &:hover {
    transition: 0.2s;
    border: 0.125rem dashed rgb(47, 141, 228);
    color: rgb(47, 141, 228);
    background-color: rgb(229, 240, 249);
  }

  &.active {
    transition: 0.2s;
    border: 0.125rem dashed rgb(47, 141, 228);
    color: rgb(47, 141, 228);
    background-color: rgb(229, 240, 249);

    &:hover {
      background-color: rgb(47, 141, 228);
      color: #fff;
    }
  }
`;

export const DropdownTitle = styled(Dropdown.Header)`
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  color: grey;
  font-weight: 500;
  text-transform: uppercase;
`;

export const DropdownItem = styled(Dropdown.Item)`
  padding: 0.3125rem 1.25rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: grey;
  font-size: 0.875rem;

  &:hover {
    background: transparent !important;
    color: #2f8de4 !important;
  }
`;

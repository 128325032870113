import { UseFormSetValue } from "react-hook-form";
import { VideoAppearanceSchema } from "./schema";

export const formatTime = (time: any) => {
  const pad = (number: any, digits: number) => {
    return number.toString().padStart(digits, "0");
  };

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const remainingSeconds = time % 60;
  const milliseconds = Math.round(
    (remainingSeconds - Math.floor(remainingSeconds)) * 1000
  );

  return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(
    Math.floor(remainingSeconds),
    2
  )}.${pad(milliseconds, 3)}`;
};

export const isVideoFile = (name: string) => {
  const videoExtensions = [
    ".mp4",
    ".mpeg",
    ".mpg",
    ".m4v",
    ".webm",
    ".ogg",
    ".heic",
    ".avi",
    ".mov",
  ];
  return videoExtensions.some((extension) =>
    name?.toLowerCase().endsWith(extension)
  );
};

export const supportsCompressedAudio = () => {
  const audioElement = document.createElement("audio");
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); // Check if browser is Safari

  const supportsOgg = audioElement.canPlayType("audio/ogg") !== "";

  return isSafari || !supportsOgg;
};

export const compareCaptionsIgnoringKey = (
  oldCaptions: any[],
  newCaptions: any[]
): any[] => {
  const differences: any[] = [];

  const length = Math.max(oldCaptions?.length, newCaptions?.length);

  for (let i = 0; i < length; i++) {
    const oldCaption = oldCaptions[i];
    const newCaption = newCaptions[i];

    if (!oldCaption && newCaption) {
      differences.push({ type: "added", newCaption });
    } else if (oldCaption && !newCaption) {
      differences.push({ type: "removed", oldCaption });
    } else if (oldCaption && newCaption) {
      const diff: any["diff"] = {};

      if (oldCaption.start !== newCaption.start) {
        diff.start = {
          before: oldCaption.start,
          after: newCaption.start,
        };
      }

      if (oldCaption.end !== newCaption.end) {
        diff.end = {
          before: oldCaption.end,
          after: newCaption.end,
        };
      }

      if (oldCaption.text !== newCaption.text) {
        diff.text = {
          before: oldCaption.text,
          after: newCaption.text,
        };
      }

      if (Object.keys(diff).length > 0) {
        differences.push({ index: i, diff, key: oldCaption.key });
      }
    }
  }

  return differences;
};

export const padZero = (num: number): string => {
  return num.toString().padStart(2, "0");
};

export const formatVideoTime = (time: number | undefined): string => {
  if (time === undefined) return "00:00:00";
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
};

export const updateGoogleFontsLink = (
  selectedFont: any,
  setValue: UseFormSetValue<VideoAppearanceSchema>
) => {
  const linkElement = document.getElementById(
    "editor-preview-fonts-link"
  ) as HTMLLinkElement;
  if (linkElement) {
    linkElement.href = `https://fonts.googleapis.com/css2?family=${selectedFont?.family?.replace(
      " ",
      "+"
    )}&display=swap`;
  } else {
    const newLink = document.createElement("link");
    newLink.id = "editor-preview-fonts-link";
    newLink.rel = "stylesheet";
    newLink.href = `https://fonts.googleapis.com/css2?family=${selectedFont?.family?.replace(
      " ",
      "+"
    )}&display=swap`;
    document.head.appendChild(newLink);
  }
  setValue("appearance.fontFamily", selectedFont.family);
};

import { useMediaQuery } from "react-responsive";
import MobileView from "./mobile";
import { MOBILE_VIEW } from "../../../../../variables";
import { useCallback, useEffect } from "react";
import useTranscript from "../../../../../hooks/useTranscript";
import { useVideoEditor } from "../context";

const Selections = () => {
  const { setValue } = useVideoEditor();
  const { transcript } = useTranscript();
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });

  const updateGoogleFontsLink = useCallback(
    (selectedFont: any) => {
      const linkElement = document.getElementById(
        "editor-preview-fonts-link"
      ) as HTMLLinkElement;
      if (linkElement) {
        linkElement.href = `https://fonts.googleapis.com/css2?family=${selectedFont?.family?.replace(
          " ",
          "+"
        )}&display=swap`;
      } else {
        const newLink = document.createElement("link");
        newLink.id = "editor-preview-fonts-link";
        newLink.rel = "stylesheet";
        newLink.href = `https://fonts.googleapis.com/css2?family=${selectedFont?.family?.replace(
          " ",
          "+"
        )}&display=swap`;
        document.head.appendChild(newLink);
      }
      setValue("appearance.fontFamily", selectedFont.family);
    },
    [setValue]
  );

  useEffect(() => {
    if (transcript?.appearance?.fontFamily) {
      setValue("appearance", transcript?.appearance);
      updateGoogleFontsLink({
        family: transcript?.appearance?.fontFamily,
      });
    }
  }, [setValue, transcript?.appearance, updateGoogleFontsLink]);

  return isMobile ? (
    <MobileView updateGoogleFontsLink={updateGoogleFontsLink} />
  ) : null;
};

export default Selections;

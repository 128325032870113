import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../../variables";
import {
  ContentWrapper,
  PanelGroupWrapper,
  PanelWrapper,
  PanelResizeHandleWrapper,
  PanelGrip,
} from "./styled";
import { getIcon } from "../../../../../utils/get-icon";
import VideoPlayer from "../video-player";
import Appearance from "../appearance";
import Selections from "../selections";
import Slider from "../slider";
import Timeline from "../timeline";
import { useVideoEditor } from "../context";

interface EditorDisplayProps {
  setCurrentTime: any;
  setDataLoaded: any;
  dataLoaded: boolean;
  setShowError: any;
}

const EditorDisplay = ({
  setCurrentTime,
  setDataLoaded,
  dataLoaded,
  setShowError,
}: EditorDisplayProps) => {
  const { audioUrl, videoUrl, isFullscreen } = useVideoEditor();
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });

  return (
    <div>
      {isMobile ? (
        <>
          <VideoPlayer videoUrl={videoUrl} setCurrentTime={setCurrentTime} />
          <Selections />
        </>
      ) : (
        <ContentWrapper>
          {isFullscreen ? (
            <VideoPlayer videoUrl={videoUrl} setCurrentTime={setCurrentTime} />
          ) : (
            <PanelGroupWrapper direction="horizontal" id="group">
              <PanelWrapper
                minSize={20.5}
                maxSize={35}
                defaultSize={30}
                id="left-panel"
              >
                <Timeline />
              </PanelWrapper>
              <PanelResizeHandleWrapper id="resize-handle-left">
                <PanelGrip>{getIcon("grip")}</PanelGrip>
              </PanelResizeHandleWrapper>
              <PanelWrapper id="main-panel" maxSize={50}>
                <VideoPlayer
                  videoUrl={videoUrl}
                  setCurrentTime={setCurrentTime}
                />
              </PanelWrapper>
              <PanelResizeHandleWrapper id="resize-handle-right">
                <PanelGrip>{getIcon("grip")}</PanelGrip>
              </PanelResizeHandleWrapper>
              <PanelWrapper
                minSize={20}
                maxSize={25}
                defaultSize={20}
                id="right-panel"
              >
                <Appearance />
              </PanelWrapper>
            </PanelGroupWrapper>
          )}
        </ContentWrapper>
      )}
      <Slider
        audioUrl={audioUrl?.default}
        audioUrlMobile={audioUrl?.mobile}
        setDataLoaded={setDataLoaded}
        dataLoaded={dataLoaded}
        setShowError={setShowError}
      />
    </div>
  );
};

export default EditorDisplay;

import { ColorResult } from "react-color";
import ColorSelect from "../../../../../../library/color-select";
import { Label } from "../../../../../../library/label";
import {
  AccordionTitle,
  Field,
  InnerAccordionHeader,
  InputCol,
  InputRow,
} from "../styled";
import { AccordionBody, AccordionHeader, AccordionItem } from "../styled";
import { VideoAppearance } from "../../../../../../types";
import { useVideoEditor } from "../../context";

interface IBackgroundSettingsProps {
  isMobile: boolean;
  appearance: VideoAppearance;
  keyRef: string;
}

const BackgroundSettings: React.FC<IBackgroundSettingsProps> = ({
  isMobile,
  appearance,
  keyRef,
}) => {
  const { setValue } = useVideoEditor();

  const handleBackgroundColorChange = (color: ColorResult) => {
    const { r, g, b, a } = color.rgb;
    const rgbaString = `rgba(${r}, ${g}, ${b}, ${a})`;
    const updatedAppearance = {
      ...appearance,
      backgroundColor: rgbaString,
    };
    setValue("appearance", updatedAppearance);
  };

  const onResetFontBackground = () => {
    const updatedAppearance = {
      ...appearance,
      backgroundColor: null,
    };
    setValue("appearance", updatedAppearance);
  };

  return (
    <AccordionItem key={keyRef} eventKey={keyRef}>
      <AccordionHeader>
        <InnerAccordionHeader>
          <AccordionTitle>Bakgrunn</AccordionTitle>
        </InnerAccordionHeader>
      </AccordionHeader>
      <AccordionBody>
        <Field isMobile={isMobile}>
          <InputRow>
            <InputCol md={12}>
              <Label>Farge</Label>
              <ColorSelect
                color={appearance?.backgroundColor || ""}
                onChange={handleBackgroundColorChange}
                onReset={onResetFontBackground}
              />
            </InputCol>
          </InputRow>
        </Field>
      </AccordionBody>
    </AccordionItem>
  );
};

export default BackgroundSettings;

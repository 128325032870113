export const defaultPeaks = [
  [
    10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 10, 7.5, 5, 7.51, 7.5, 5, 7.5,
    10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5,
    5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5,
    10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5,
    5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5,
    10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5,
    5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5,
    10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5,
    5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5,
    10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5, 5, 7.5, 10, 7.5,
    5, 7.5, 10,
  ],
];

import styled from "@emotion/styled";

export const SliderWrapper = styled.div`
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 1000;
  align-items: center;
  padding: 0px 10px;
  border-top: 1px solid rgb(225, 225, 225);
  &.hide {
    display: none;
  }
`;

export const WaveformWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  padding: ${({ isMobile }) => (isMobile ? "15px 0px" : "0px 0px")};
`;

export const TimeDisplay = styled.span`
  font-size: 14px;
  font-family: Poppins, sans-serif;
  color: #3b3b3b;
  font-weight: 600;
`;

export const InteractionButton = styled.button`
  border: none;
  background: none;
  font-size: 22px;
  color: #2f8de4;
  padding: 0px;

  &.first {
    margin-right: 10px;
  }
  &.last {
    margin-left: 10px;
  }
`;

export const PlayButton = styled.button`
  width: 50px;
  font-size: 30px;
  border: none;
  background: none;
  color: #2f8de4;
  margin: 0px 5px;
`;

export const TimeWrapper = styled.div`
  width: 80px;
  padding: 0px 20px;
  text-align: center;
  &:first-of-type {
    padding-left: 10px;
  }
  &:last-of-type {
    padding-right: 10px;
  }
`;

export const WavesurferWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  padding: ${({ isMobile }) => (isMobile ? "0px 0px" : "0px 10px")};
  &:hover {
    cursor: pointer;
  }
`;

export const ControllerWrapper = styled.div`
  padding-top: 5px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
`;

export const BottomWrapper = styled.div<{ isMobile: boolean }>`
  padding-bottom: ${({ isMobile }) => (isMobile ? "0px" : "7.5px")};
`;

export const TopWrapper = styled.div`
  display: flex;
  padding-bottom: 5px;
  align-items: center;
  justify-content: center;
`;

export const VolumeWrapper = styled.div`
  padding-right: 10px;
`;

export const VolumeInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 12.5px;
  outline: none;
  background: none;
  cursor: pointer;
  max-width: 75px;
  margin-right: 10px;

  &::-webkit-slider-runnable-track {
    width: 10%;
    height: 8px;
    background: linear-gradient(
      to right,
      #2f8de4 0%,
      #2f8de4 var(--player-volume, 0%),
      #eaeaea var(--player-volume, 0%),
      #eaeaea 100%
    );
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 13px;
    height: 13px;
    margin-top: -3px;
    background: #2f8de4;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-track {
    width: 100%;
    height: 3px;
    background: #ddd;
    border-radius: 5px;
  }

  &::-moz-range-thumb {
    width: 12.5px;
    height: 12.5px;
    background: #007bff;
    border-radius: 50%;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

import { useCallback, useState } from "react";
import { IState } from "../types";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebase";
import { useAppSelector } from "../redux/hooks";

const useMonitorTranscript = () => {
  const auth = getAuth(firebaseApp);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const selectedWorkspace = useAppSelector(
    (state: IState) => state.user.selectedWorkspace
  );

  const monitorTranscript = useCallback(
    async (transcriptId: string) => {
      const token = await auth.currentUser?.getIdToken();

      if (!token || !selectedWorkspace.id || !transcriptId) {
        throw new Error("Missing required data for transcript monitoring.");
      }

      try {
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/transcription/status?workspaceId=${selectedWorkspace.id}&transcriptId=${transcriptId}&token=${token}`,
          { method: "GET" }
        );
        const response = await request.json();

        if (response.error === null) {
          return response.status;
        } else {
          throw response.error;
        }
      } catch (e) {
        setIsPolling(false);
        throw e;
      }
    },
    [auth?.currentUser, selectedWorkspace?.id]
  );

  return {
    monitorTranscript,
    isPolling,
    setIsPolling,
  };
};

export default useMonitorTranscript;

import moment from "moment";
import short from "short-uuid";
import sanitizeHtml from "sanitize-html";
import { Caption as CaptionType } from "../../../../../../types";
import { Dispatch, SetStateAction } from "react";

export const INPUT_TIME_PLACEHOLDER = "HH:MM:SS.MS";
export const INPUT_TIME_PATTERN = "d{2}:d{2}:d{2}.d{3}";

export const convertFloat = (time: string) => {
  const timeMoment = moment(time, "HH:mm:ss,SSS");

  const totalTimeSeconds = timeMoment.diff(moment().startOf("day"), "seconds");

  const totalTimeFloat = totalTimeSeconds;

  return parseFloat(totalTimeFloat.toFixed(3));
};

export const formatTimestamp = (input: string): string => {
  const cleaned = input.replace(/\D/g, "");

  const hours = cleaned.slice(0, 2).padEnd(2, "0");
  const minutes = cleaned.slice(2, 4).padEnd(2, "0");
  const seconds = cleaned.slice(4, 6).padEnd(2, "0");
  const milliseconds = cleaned.slice(6, 9).padEnd(3, "0");

  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export const isValidTimestamp = (value: string): boolean => {
  return /^\d{2}:\d{2}:\d{2}\.\d{3}$/.test(value);
};

export const convertToFloat = (timestamp: string): number => {
  const [hours, minutes, secondsMilliseconds] = timestamp.split(":");
  const [seconds, milliseconds] = secondsMilliseconds.split(".");

  return (
    parseInt(hours, 10) * 3600 +
    parseInt(minutes, 10) * 60 +
    parseInt(seconds, 10) +
    parseInt(milliseconds, 10) / 1000
  );
};

export const renderActive = (
  currentTime: number,
  start: string,
  end: string
) => {
  if (!start || !end) return;
  const startTime = convertFloat(start);
  const endTime = convertFloat(end);

  return currentTime >= startTime && currentTime <= endTime;
};

export const selectCaption = (
  currentTime: number,
  start: string,
  end: string,
  onSeek: (time: number) => void
) => {
  const startTime = convertFloat(start);
  const endTime = convertFloat(end);
  if (currentTime >= startTime && currentTime <= endTime) return;

  const duration = endTime - startTime;
  const percentage = 0.25;
  const offset = duration * percentage;

  const seekTime = startTime + offset;
  onSeek(seekTime);
};

export const clickNewCaption = (
  captions: any[],
  caption: any,
  setValue: (name: string, value: any) => void
) => {
  const index = captions.findIndex((c: any) => c.key === caption.key);
  if (index !== -1) {
    const newCaptions = [...captions];
    const currentDuration = newCaptions[index].end - newCaptions[index].start;
    const currentChange = currentDuration * 0.2;

    newCaptions[index] = {
      ...caption,
      end: (caption.end as any) - currentChange,
    };

    if (index + 1 < newCaptions.length) {
      const currentDuration =
        newCaptions[index + 1].end - newCaptions[index + 1].start;
      const currentChange = currentDuration * 0.2;

      newCaptions[index + 1] = {
        ...newCaptions[index + 1],
        start: newCaptions[index + 1].start + currentChange,
      };
    }

    const newCaption: CaptionType = {
      key: short.generate(),
      start: newCaptions[index].end + currentChange * 0.25,
      end:
        index + 1 < newCaptions.length
          ? newCaptions[index + 1].start - currentChange * 0.25
          : newCaptions[index].end + 1,
      text: "",
    };

    newCaptions.splice(index + 1, 0, newCaption);

    setValue("captions", newCaptions);
  }
};

const updateCaption = (
  key: string,
  updates: Partial<any>,
  captions: any[],
  setValue: (name: string, value: any) => void
) => {
  const updatedCaptions = captions.map((caption) =>
    caption.key === key ? { ...caption, ...updates } : caption
  );

  setValue("captions", updatedCaptions);
};

export const handleTimestampChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  type: "start" | "end",
  duration: { start: string; end: string },
  setDuration: (
    value: SetStateAction<{
      start: string;
      end: string;
    }>
  ) => void
) => {
  const input = e.target.value;
  setDuration({ ...duration, [type]: input });
};

export const onContentBlur = (
  key: string,
  content: string,
  latestCaptions: any[],
  setValue: (name: string, value: any) => void
) => {
  const sanitizedHTML = sanitizeHtml(content, {
    allowedTags: [],
    allowedAttributes: {},
  });

  const cleanedHTML = sanitizedHTML.replace(/&nbsp;/g, " ");

  const div = document.createElement("div");
  div.innerHTML = cleanedHTML;

  const cleanText = div.textContent || div.innerText || "";

  updateCaption(key, { text: cleanText }, latestCaptions, setValue);
};

export const handleTimestampBlur = (
  key: string,
  type: "start" | "end",
  duration: { start: string; end: string },
  captions: any,
  handleAdjust: (key: string, method: "delete" | "change") => void,
  setValue: (name: string, value: any) => void,
  setDuration: Dispatch<
    SetStateAction<{
      start: string;
      end: string;
    }>
  >
) => {
  let similar = false;
  const value = duration[type];
  const formattedValue = formatTimestamp(value);

  if (isValidTimestamp(formattedValue)) {
    const floatValue = convertToFloat(formattedValue);

    updateCaption(key, { [type]: floatValue }, captions, setValue);

    const comparison = captions.find((c: any) => c.key === key);
    if (comparison[type] === floatValue) {
      similar = true;
    }
  }

  setDuration({ ...duration, [type]: formattedValue });

  if (!similar && type === "end") {
    handleAdjust(key, "change");
  }
};

import ReactTimeAgo from "react-time-ago";
import {
  ButtonsWrapper,
  FullTitleWrapper,
  GoBackIcon,
  LastSavedLabel,
  Logo,
  ReturnWrapper,
  TitleWrapper,
  TopWrapper,
  UpperTopWrapper,
} from "./styled";
import logoLight from "../../../../../../assets/img/logo-single.svg";
import ExportDetails from "../../../../../../components/ExportModal";
import { getIcon } from "../../../../../../utils/get-icon";
import Button from "../../../../../../library/button";
import Modal from "../../../../../../library/modal";
import { Dispatch, SetStateAction, useState } from "react";
import useTranscript from "../../../../../../hooks/useTranscript";
import { Link } from "react-router-dom";
import { Title } from "../../../../../../library/title";
import RevisionModal from "./revision-modal";
import { useVideoEditor } from "../../context";

interface DesktopTopBarProps {
  onGenerateVideo: () => void;
  generateLoading: boolean;
  progress: number;
  lastUpdated?: number;
  transcriptId?: string;
  title?: string;
  videoDownloadUrl?: string;
  setVideoDownloadUrl?: Dispatch<SetStateAction<string | undefined>>;
}

const DesktopTopBar = ({
  onGenerateVideo,
  generateLoading,
  lastUpdated = 0,
  progress,
  title,
  transcriptId,
  videoDownloadUrl,
  setVideoDownloadUrl,
}: DesktopTopBarProps) => {
  const { captions, appearance } = useVideoEditor();
  const { transcript } = useTranscript();
  const [showRevisionHistoryModal, setShowRevisionHistoryModal] =
    useState<boolean>(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const openExportModal = () => {
    setShowExportModal(true);
  };

  const closeExportModal = () => {
    setShowExportModal(false);
    if (videoDownloadUrl) {
      setVideoDownloadUrl && setVideoDownloadUrl(undefined);
    }
  };

  const onExport = () => {
    openExportModal();
  };

  const openRevisionHistoryModal = () => {
    setShowRevisionHistoryModal(true);
  };

  const closeRevisionHistoryModal = () => {
    setShowRevisionHistoryModal(false);
  };

  return (
    <>
      <TopWrapper>
        <UpperTopWrapper>
          <ReturnWrapper>
            <Link to="/dashboard/transcripts">
              <GoBackIcon>{getIcon("editor-back")}</GoBackIcon>
              <Logo src={logoLight} />
            </Link>
            <FullTitleWrapper>
              <TitleWrapper>
                <Title size="xs">{title}</Title>
              </TitleWrapper>
              <LastSavedLabel>
                Sist lagret <ReactTimeAgo date={lastUpdated * 1000} />
              </LastSavedLabel>
            </FullTitleWrapper>
          </ReturnWrapper>
          <ButtonsWrapper>
            <Button
              onClick={openRevisionHistoryModal}
              variant="regular"
              icon="revision"
              spacing="right"
              color="grey"
              disabled={true}
            >
              Revisjonshistorikk
            </Button>
            <Button
              onClick={onExport}
              icon="download"
              variant="regular"
              color="blue"
            >
              Eksporter
            </Button>
          </ButtonsWrapper>
        </UpperTopWrapper>
      </TopWrapper>
      <Modal open={showExportModal} onClose={closeExportModal} title="Last ned">
        <ExportDetails
          onClose={closeExportModal}
          transcriptId={transcriptId ?? ""}
          onGenerateVideo={onGenerateVideo}
          generateLoading={generateLoading}
          variant="editor"
          progress={progress}
          videoDownloadUrl={videoDownloadUrl}
        />
      </Modal>
      <Modal
        open={showRevisionHistoryModal}
        onClose={closeRevisionHistoryModal}
        title="Revisjonshistorikk"
        size={1200}
      >
        <RevisionModal
          videoUrl={transcript.videoUrl}
          original={{ captions, appearance }}
          onClose={closeRevisionHistoryModal}
        />
      </Modal>
    </>
  );
};

export default DesktopTopBar;

import styled from "@emotion/styled";

export const TableRow = styled.tr`
  border-bottom: 0.0625rem solid rgb(235, 235, 235);

  &:last-of-type {
    border: none !important;
  }
`;

export const Value = styled.span`
  font-family: "Poppins", sans-serif;
  color: grey;
  font-size: 0.875rem;

  a {
    font-family: "Poppins", sans-serif;
    color: grey;
    text-decoration: none;
  }
  &.bold {
    font-weight: 500;
  }
`;

export const ProgressWrapper = styled.span`
  margin-right: 0.625rem;
`;

export const Status = styled.span`
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  background: none;
  &.completed {
    color: rgb(80, 205, 137);
  }
  &.progress {
    color: rgb(255, 199, 0);
  }
  &.failed {
    color: rgb(241, 65, 108);
  }
`;

export const Badge = styled.span`
  background-color: yellow;
  font-size: 0.75rem;
  padding: 0.4688rem 0.625rem;
  border-radius: 0.25rem;
  display: inline;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  background-color: rgb(241, 250, 255);
  color: rgb(0, 158, 247);
`;

export const TableData = styled.td`
  white-space: nowrap;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  &:nth-of-type(2) {
    max-width: 370px;
    padding-right: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    text-decoration: none !important;
  }
  &:first-of-type {
    max-width: 2.5rem !important;
  }

  &:last-of-type {
    float: right;
  }

  @media (max-width: 1100px) {
    &:nth-of-type(2) {
      max-width: 200px;
    }
  }
  @media (max-width: 900px) {
    &:nth-of-type(3),
    &:nth-of-type(4) {
      display: none;
    }
    &:nth-of-type(2) {
      max-width: 350px;
      padding-right: 40px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (max-width: 575px) {
    &:nth-of-type(2) {
      padding-right: 10px;
      max-width: 180px;
    }
  }

  @media (max-width: 450px) {
    &:nth-of-type(2) {
      max-width: 150px;
    }
  }
`;

export const ButtonsWrapper = styled.span`
  display: flex;
  gap: 10px;
  button {
    height: 2.5rem !important;
    gap: 10px;
  }
`;

export const ProgressBarWrapper = styled.span`
  width: 100%;
`;

import React, { createContext, useContext, useEffect, useRef } from "react";

const CaptionsContext = createContext<any>(null);

export const useVideoEditor = () => useContext(CaptionsContext);

export const CaptionsProvider = ({
  captions,
  setValue,
  fonts,
  appearance,
  videoPlayerRef,
  videoPlayerHeight,
  videoPlayerWidth,
  activeCaptionRef,
  scrollingContainerRef,
  audioUrl,
  videoUrl,
  register,
  currentPlayerTime,
  peaks,
  isPlaying,
  setIsPlaying,
  togglePlayPause,
  isFullscreen,
  setIsFullscreen,
  children,
}: any) => {
  const captionsRef = useRef(captions);

  // Keep the ref updated with the latest state
  useEffect(() => {
    captionsRef.current = captions;
  }, [captions]);

  return (
    <CaptionsContext.Provider
      value={{
        captions,
        setValue,
        appearance,
        videoPlayerRef,
        videoPlayerHeight,
        videoPlayerWidth,
        fonts,
        activeCaptionRef,
        scrollingContainerRef,
        audioUrl,
        videoUrl,
        register,
        captionsRef,
        currentPlayerTime,
        peaks,
        isPlaying,
        setIsPlaying,
        togglePlayPause,
        isFullscreen,
        setIsFullscreen,
      }}
    >
      {children}
    </CaptionsContext.Provider>
  );
};

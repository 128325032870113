import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  BottomWrapper,
  Content,
  InnerContainer,
  LoginContainer,
  LoginForm,
  Logo,
  LogoContainer,
  ResetPasswordText,
  Section,
} from "./styled";
import logo from "../../assets/img/logo-2.svg";
import Button from "../../library/button";
import { Title } from "../../library/title";
import { firebaseApp } from "../../firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { sendErrorNotification } from "../../library/notification";
import { useTranslation } from "react-i18next";
import { LoginSchema, schema } from "./schema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Meta from "./meta";
import { FIELDS } from "./fields";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const auth = getAuth(firebaseApp);

  if (auth.currentUser?.uid) {
    return <Navigate to="/dashboard/transcripts" replace />;
  }

  const performLogin = async (data: LoginSchema) => {
    try {
      const login = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      if (login) {
        navigate("/dashboard/transcripts");
      } else {
        throw new Error("Wrong username or password");
      }
    } catch (err) {
      sendErrorNotification("Feil brukeravn eller passord.");
    }
  };

  return (
    <>
      <Meta />
      <Section>
        <InnerContainer>
          <Content>
            <LogoContainer>
              <Link to="/">
                <Logo src={logo} />
              </Link>
            </LogoContainer>
            <LoginContainer>
              <Title>{t("login.title")}</Title>
              <LoginForm onSubmit={handleSubmit(performLogin)}>
                {FIELDS.map((field) => field.renderField(register, errors))}
                <Button
                  className="login-button"
                  type="submit"
                  variant="regular"
                  color="blue"
                  ariaLabel={t("login.form.login-button.ariaLabel") as string}
                >
                  {t("login.form.login-button.title")}
                </Button>
                <BottomWrapper>
                  <Link
                    aria-label={
                      t("login.form.register-link.ariaLabel") as string
                    }
                    to="/register"
                  >
                    <Button type="button" variant="regular" color="grey">
                      {t("login.form.register-button.title")}
                    </Button>
                  </Link>
                  <ResetPasswordText>
                    {t("login.form.forgot-password.text")}{" "}
                    <Link
                      aria-label={
                        t("login.form.forgot-password.link.ariaLabel") as string
                      }
                      to="/forgot-password"
                    >
                      {t("login.form.forgot-password.link.title")}
                    </Link>
                    .
                  </ResetPasswordText>
                </BottomWrapper>
              </LoginForm>
            </LoginContainer>
          </Content>
        </InnerContainer>
      </Section>
    </>
  );
};

export default Login;

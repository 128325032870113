import { useEffect, useRef, useState } from "react";
import Caption from "./caption";
import {
  DesktopTopWrapper,
  EmptyWrapper,
  SearchWrapper,
  TimelineContainer,
  TitleWrapper,
} from "./styled";
import Modal from "../../../../../library/modal";
import DeleteCaptionModalContent from "./delete-modal";
import AdjustTimestampModalContent from "./timestamp-modal";
import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../../variables";
import { Title } from "../../../../../library/title";
import Input from "../../../../../library/input";
import EmptyView from "../../../../../components/EmptyView";
import { useVideoEditor } from "../context";
import { Virtuoso } from "react-virtuoso";

export const Timeline = () => {
  const {
    captions,
    setValue,
    videoPlayerRef,
    scrollingContainerRef,
    activeCaptionRef,
    currentPlayerTime,
  } = useVideoEditor();
  const virtuosoRef = useRef<any>(null);

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);

  const [showDeleteCaption, setShowDeleteCaption] = useState(false);
  const [showAdjustCaption, setShowAdjustCaption] = useState(false);

  const [containerHeight, setContainerHeight] = useState(0);

  const filteredCaptions = searchQuery
    ? captions.filter((caption: any) =>
        caption?.text?.toLowerCase().includes(searchQuery?.toLowerCase())
      )
    : captions;

  const closeAdjustCaptionModal = () => {
    setShowAdjustCaption(false);
  };

  const openAdjustCaptionModal = () => {
    setShowAdjustCaption(true);
  };

  const closeDeleteCaptionModal = () => {
    setShowDeleteCaption(false);
  };

  const openDeleteCaptionModal = () => {
    setShowDeleteCaption(true);
  };

  const handleAdjust = (key: string, method: "change" | "delete") => {
    setActiveKey(key);

    if (method === "change") {
      openAdjustCaptionModal();
    } else {
      openDeleteCaptionModal();
    }
  };

  const clickSeek = (start: number) => {
    if (!videoPlayerRef.current || typeof start !== "number") return;
    if (!Number.isNaN(start)) {
      videoPlayerRef.current.currentTime = start;
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateContainerSize = () => {
    if (scrollingContainerRef.current) {
      setContainerHeight(scrollingContainerRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    updateContainerSize();

    window.addEventListener("resize", updateContainerSize);

    return () => {
      window.removeEventListener("resize", updateContainerSize);
    };
  }, [updateContainerSize]);

  useEffect(() => {
    if (activeCaptionRef.current && virtuosoRef?.current) {
      const activeIndex = filteredCaptions.findIndex(
        (caption: any) => caption.key === activeCaptionRef.current?.id
      );

      if (activeIndex !== -1) {
        virtuosoRef.current.scrollToIndex({
          index: activeIndex,
          align: "center",
        });
      }
    }
  }, [
    activeCaptionRef,
    filteredCaptions,
    scrollingContainerRef,
    currentPlayerTime,
  ]);

  return (
    <>
      {!isMobile && (
        <DesktopTopWrapper>
          <TitleWrapper>
            <Title size="xs">Undertekster</Title>
          </TitleWrapper>
          <SearchWrapper>
            <Input
              onChange={handleSearch}
              placeholder="Søk etter tekst.."
              variant="search"
              value={searchQuery}
            />
          </SearchWrapper>
        </DesktopTopWrapper>
      )}
      <TimelineContainer isMobile={isMobile} ref={scrollingContainerRef}>
        {filteredCaptions.length > 0 ? (
          <Virtuoso
            ref={virtuosoRef}
            style={{ height: containerHeight }}
            totalCount={filteredCaptions.length}
            itemContent={(index) => (
              <Caption
                caption={filteredCaptions[index]}
                handleAdjust={handleAdjust}
                clickSeek={clickSeek}
                reference={activeCaptionRef}
                index={index}
                key={filteredCaptions[index].key}
              />
            )}
          />
        ) : (
          <EmptyWrapper>
            <EmptyView
              size="small"
              title="Ingen undertekster funnet"
              description="Videoen du lastet opp har ingen undertekster som matcher søket ditt."
            />
          </EmptyWrapper>
        )}
        <Modal
          open={showAdjustCaption}
          onClose={closeAdjustCaptionModal}
          title="Endre påfølgende tidskoder?"
          size={495}
        >
          <AdjustTimestampModalContent
            onClose={closeAdjustCaptionModal}
            captionKey={activeKey}
            captions={captions}
            setValue={setValue}
          />
        </Modal>
        <Modal
          open={showDeleteCaption}
          onClose={closeDeleteCaptionModal}
          title="Slette undertekst?"
          size={475}
        >
          <DeleteCaptionModalContent
            onClose={closeDeleteCaptionModal}
            captionKey={activeKey}
            setValue={setValue}
            captions={captions}
          />
        </Modal>
      </TimelineContainer>
    </>
  );
};

export default Timeline;

import { useMediaQuery } from "react-responsive";
import { Caption as CaptionType } from "../../../../../../types";
import DesktopCaption from "./desktop";
import MobileCaption from "./mobile";
import { MOBILE_VIEW } from "../../../../../../variables";

interface CaptionProps {
  reference: any;
  caption: CaptionType;
  handleAdjust: (key: string, method: "change" | "delete") => void;
  clickSeek: (time: number) => void;
  index: number;
}

const Caption = ({
  reference,
  caption,
  handleAdjust,
  clickSeek,
}: CaptionProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });

  const MENU_SELECTIONS = [
    {
      display: "Slett tekst",
      onClick: () => handleAdjust(caption.key, "delete"),
    },
  ];

  return isMobile ? (
    <MobileCaption
      captionRef={reference}
      caption={caption}
      handleAdjust={handleAdjust}
      selections={MENU_SELECTIONS}
      clickSeek={clickSeek}
    />
  ) : (
    <DesktopCaption
      captionRef={reference}
      caption={caption}
      handleAdjust={handleAdjust}
      clickSeek={clickSeek}
      selections={MENU_SELECTIONS}
    />
  );
};

export default Caption;

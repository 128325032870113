import { useEffect } from "react";
import VideoPlayerDesktop from "./desktop";
import VideoPlayerMobile from "./mobile";
import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../../variables";
import { useVideoEditor } from "../context";

type VideoPlayerProps = {
  videoUrl: string;
  setCurrentTime: any;
};

const VideoPlayer = ({ videoUrl, setCurrentTime }: VideoPlayerProps) => {
  const {
    captions,
    videoPlayerRef,
    currentPlayerTime,
    setIsPlaying,
    togglePlayPause,
  } = useVideoEditor();
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });

  const activeSubtitle = () => {
    if (!captions) return "";
    const currentCaption = captions.find(
      (caption: any) =>
        currentPlayerTime >= caption.start && currentPlayerTime <= caption.end
    );
    return currentCaption ? currentCaption?.text : "";
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.code === "Space" &&
        event.target instanceof HTMLElement &&
        !["INPUT", "TEXTAREA", "DIV"].includes(event.target.tagName)
      ) {
        if (videoPlayerRef.current.paused || videoPlayerRef.current.ended) {
          videoPlayerRef.current.play();
          setIsPlaying(true);
        } else {
          videoPlayerRef.current.pause();
          setIsPlaying(false);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [setIsPlaying, videoPlayerRef]);

  const handleTimeUpdate = () => {
    if (!videoPlayerRef.current) return;
    const time = videoPlayerRef.current.currentTime;
    setCurrentTime(time);
  };

  return isMobile ? (
    <VideoPlayerMobile
      togglePlayPause={togglePlayPause}
      onTimeUpdate={handleTimeUpdate}
      videoRef={videoPlayerRef}
      videoUrl={videoUrl}
      activeSubtitle={activeSubtitle}
    />
  ) : (
    <VideoPlayerDesktop
      videoUrl={videoUrl}
      activeSubtitle={activeSubtitle}
      onTimeUpdate={handleTimeUpdate}
    />
  );
};

export default VideoPlayer;

import { useCallback, useEffect, useRef, useState } from "react";
import {
  BottomWrapper,
  ControllerWrapper,
  PlayButton,
  SliderWrapper,
  InteractionButton,
  TimeDisplay,
  TimeWrapper,
  TopWrapper,
  WaveformWrapper,
  WavesurferWrapper,
  VolumeWrapper,
  VolumeInput,
} from "./styled";
import WaveSurfer from "wavesurfer.js";
import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../../variables";
import { formatVideoTime, supportsCompressedAudio } from "../helpers";
import { useVideoEditor } from "../context";
import { defaultPeaks } from "./utils";
import { getIcon } from "../../../../../utils/get-icon";

type SliderProps = {
  audioUrl: any;
  audioUrlMobile: any;
  setDataLoaded: any;
  dataLoaded: any;
  setShowError: any;
};

const Slider = ({
  audioUrl,
  audioUrlMobile,
  setDataLoaded,
  dataLoaded,
  setShowError,
}: SliderProps) => {
  const {
    videoPlayerRef,
    currentPlayerTime,
    peaks,
    captions,
    isPlaying,
    togglePlayPause,
    isFullscreen,
    setIsFullscreen,
    activeCaptionRef,
  } = useVideoEditor();
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);

  const [volume, setVolume] = useState(1);
  const [mute, setMute] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const performSeek = (time: number) => {
    if (!videoPlayerRef.current) return;
    videoPlayerRef.current.currentTime = time;
  };

  wavesurferRef?.current?.on("ready", () => {
    setDataLoaded(true);
  });

  wavesurferRef.current?.on("error", (e) => {
    setShowError(true);
  });

  const handleSeek = useCallback(
    (event: any) => {
      if (!wavesurferRef.current || !waveformRef.current) return;

      const mouseX =
        event.clientX - waveformRef.current.getBoundingClientRect().left;
      const duration = wavesurferRef.current.getDuration();
      const seekTime = (mouseX / waveformRef.current.offsetWidth) * duration;

      performSeek(seekTime);
    },
    [performSeek]
  );

  useEffect(() => {
    if (!waveformRef.current) return;

    const currentWaveformRef = waveformRef.current;

    currentWaveformRef.addEventListener("click", handleSeek);

    return () => {
      if (currentWaveformRef) {
        currentWaveformRef.removeEventListener("click", handleSeek);
      }
    };
  }, [handleSeek]);

  useEffect(() => {
    if (!waveformRef.current) return;

    const audio = supportsCompressedAudio() ? audioUrlMobile : audioUrl;

    if (!audio) return;

    const controller = new AbortController();
    const signal = controller.signal;

    let displayPeaks;

    if (peaks) {
      displayPeaks = [...peaks];
    } else {
      displayPeaks = defaultPeaks;
    }

    wavesurferRef.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "#74bcff",
      progressColor: "#2f8de4",
      cursorWidth: 1,
      barWidth: 2,
      height: isMobile ? 20 : 12.5,
      backend: "MediaElement",
      peaks: displayPeaks,
      fetchParams: { signal },
    });

    wavesurferRef.current.load(audio, displayPeaks);

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, [audioUrl, audioUrlMobile, isMobile, peaks]);

  useEffect(() => {
    if (currentPlayerTime && videoPlayerRef.duration && wavesurferRef.current) {
      wavesurferRef.current?.seekTo(
        currentPlayerTime / videoPlayerRef.duration
      );
    }
  }, [currentPlayerTime, videoPlayerRef.duration]);

  const renderTotalTime = () => {
    return videoPlayerRef.current?.duration
      ? formatVideoTime(videoPlayerRef.current?.duration)
      : "00:00:00";
  };

  const clickGoBackward = () => {
    if (!videoPlayerRef.current) return;
    const skipTime = currentPlayerTime - 1;
    videoPlayerRef.current.currentTime = skipTime;
  };

  const clickGoForward = () => {
    if (!videoPlayerRef.current) return;
    const skipTime = currentPlayerTime + 1;
    videoPlayerRef.current.currentTime = skipTime;
  };

  const getCaptionIndexById = (captions: any, id: string) => {
    return captions.findIndex((caption: any) => caption.key === id);
  };

  const clickGoToPreviousCaption = () => {
    if (!videoPlayerRef.current || !activeCaptionRef.current?.id) return;

    if (activeCaptionRef.current?.id) {
      const currentIndex = getCaptionIndexById(
        captions,
        activeCaptionRef.current.id
      );
      if (currentIndex === -1 || currentIndex === 0) {
        videoPlayerRef.current.currentTime = captions[0].start;
        return;
      }

      const previousCaption = captions[currentIndex - 1];
      videoPlayerRef.current.currentTime = previousCaption.start;
    } else {
      videoPlayerRef.current.currentTime = captions[0].start;
    }
  };

  const clickGoToNextCaption = () => {
    if (!videoPlayerRef.current) return;

    if (activeCaptionRef.current?.id) {
      const currentIndex = getCaptionIndexById(
        captions,
        activeCaptionRef.current.id
      );
      if (currentIndex === -1 || currentIndex === captions.length - 1) {
        videoPlayerRef.current.currentTime = captions[0].start;
        return;
      }

      const nextCaption = captions[currentIndex + 1];
      videoPlayerRef.current.currentTime = nextCaption.start;
    } else {
      videoPlayerRef.current.currentTime = captions[0].start;
    }
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!videoPlayerRef?.current) return;
    const value = e.target.value;
    const volume = parseFloat(value);
    videoPlayerRef.current.volume = volume;
    document.documentElement.style.setProperty(
      "--player-volume",
      `${volume * 100}%`
    );
    setVolume(volume);
    setMute(volume === 0);
  };

  const handleMute = () => {
    if (!videoPlayerRef?.current) return;
    videoPlayerRef.current.muted = !mute;
    setMute(!mute);
  };

  const handleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <SliderWrapper className={dataLoaded ? "show" : "hide"}>
      {!isMobile && (
        <TopWrapper>
          <TimeWrapper>
            <TimeDisplay>{formatVideoTime(currentPlayerTime)}/</TimeDisplay>
            <TimeDisplay>{renderTotalTime()}</TimeDisplay>
          </TimeWrapper>
          <ControllerWrapper>
            <InteractionButton
              className="first"
              onClick={clickGoToPreviousCaption}
            >
              {getIcon("backward-step")}
            </InteractionButton>
            <InteractionButton onClick={clickGoBackward}>
              {getIcon("backward")}
            </InteractionButton>
            <PlayButton onClick={togglePlayPause}>
              {isPlaying ? getIcon("pause") : getIcon("play")}
            </PlayButton>
            <InteractionButton onClick={clickGoForward}>
              {getIcon("forward")}
            </InteractionButton>
            <InteractionButton className="last" onClick={clickGoToNextCaption}>
              {getIcon("forward-step")}
            </InteractionButton>
          </ControllerWrapper>
          <VolumeWrapper>
            <VolumeInput
              type="range"
              min={0}
              max={1}
              step={0.1}
              value={volume}
              onChange={handleVolumeChange}
            />
            <InteractionButton onClick={handleMute}>
              {!mute || volume === 0.1
                ? getIcon("volume-on")
                : getIcon("volume-off")}
            </InteractionButton>
            <InteractionButton onClick={handleFullscreen} className="last">
              {isFullscreen ? getIcon("minimise") : getIcon("expand")}
            </InteractionButton>
          </VolumeWrapper>
        </TopWrapper>
      )}
      <BottomWrapper isMobile={isMobile}>
        <WavesurferWrapper isMobile={isMobile}>
          <WaveformWrapper isMobile={isMobile} ref={waveformRef} />
        </WavesurferWrapper>
      </BottomWrapper>
    </SliderWrapper>
  );
};

export default Slider;

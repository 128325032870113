import { useState } from "react";
import {
  ContentWrapper,
  MenuButton,
  MenuWrapper,
  MobileWrapper,
} from "./styled";
import Timeline from "../../timeline";
import Appearance from "../../appearance";
import { useVideoEditor } from "../../context";

interface MobileViewProps {
  updateGoogleFontsLink: any;
}

const MobileView = ({ updateGoogleFontsLink }: MobileViewProps) => {
  const { videoPlayerHeight, scrollingContainerRef } = useVideoEditor();
  const [selection, setSelection] = useState<"appearance" | "captions">(
    "captions"
  );

  const handleSelection = (selection: "appearance" | "captions") => {
    setSelection(selection);
  };

  return (
    <MobileWrapper playerHeight={videoPlayerHeight ?? 0}>
      <MenuWrapper playerHeight={videoPlayerHeight ?? 0}>
        <MenuButton
          className={selection === "captions" ? "active" : ""}
          onClick={() => handleSelection("captions")}
        >
          Undertekster
        </MenuButton>
        <MenuButton
          className={selection === "appearance" ? "active" : ""}
          onClick={() => handleSelection("appearance")}
        >
          Endre utseende
        </MenuButton>
      </MenuWrapper>
      <ContentWrapper
        ref={scrollingContainerRef}
        playerHeight={videoPlayerHeight ?? 0}
      >
        {selection === "captions" ? <Timeline /> : <Appearance />}
      </ContentWrapper>
    </MobileWrapper>
  );
};

export default MobileView;

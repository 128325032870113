import { VideoAppearance } from "../../../../types";

export const defaultApperance = {
  alignment: {
    horizontal: "center",
    vertical: "bottom",
  },
  backgroundColor: null,
  fontFamily: "Poppins",
  fontSize: "20",
  color: "rgba(255, 255, 255, 1)",
  decoration: "normal",
  fontStyle: "normal",
} satisfies VideoAppearance;

import { Navigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebase";

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const auth = getAuth(firebaseApp);

  // loading the auth state
  if (auth.currentUser === undefined) {
    return null;
  }

  // user is not logged in
  if (!auth.currentUser) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;

import { Caption as CaptionType } from "../../../../../../../types";
import { getIcon } from "../../../../../../../utils/get-icon";
import {
  AddButton,
  BindingIcon,
  CaptionWrapper,
  MenuButton,
  TimestampInput,
  UpperCol,
  UpperRow,
} from "./styled";
import ContentEditable from "react-contenteditable";
import Dropdown from "../../../../../../../library/dropdown-menu";
import {
  ForwardRefRenderFunction,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { formatTime } from "../../../helpers";
import {
  clickNewCaption,
  handleTimestampBlur,
  handleTimestampChange,
  INPUT_TIME_PATTERN,
  INPUT_TIME_PLACEHOLDER,
  onContentBlur,
  renderActive,
  selectCaption,
} from "../utils";
import { useVideoEditor } from "../../../context";

type MobileCaptionProps = {
  caption: CaptionType;
  selections: any[];
  handleAdjust: (key: string, method: "delete" | "change") => void;
  clickSeek: (time: number) => void;
  captionRef: RefObject<HTMLDivElement>;
};

const MobileCaption: ForwardRefRenderFunction<
  HTMLDivElement,
  MobileCaptionProps
> = ({ caption, selections, handleAdjust, clickSeek, captionRef }) => {
  const { currentPlayerTime, setValue, captionsRef } = useVideoEditor();
  const [currentText, setCurrentText] = useState(caption?.text || "");

  const start = formatTime(caption?.start);
  const end = formatTime(caption?.end);

  const startInputRef = useRef<HTMLInputElement | null>(null);
  const endInputRef = useRef<HTMLInputElement | null>(null);

  const [duration, setDuration] = useState<{ start: string; end: string }>({
    start: "",
    end: "",
  });

  const blurInput = (type: "start" | "end") => {
    if (type === "start") {
      startInputRef.current?.blur();
    } else {
      endInputRef.current?.blur();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    key: string,
    type: "start" | "end"
  ) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      ":",
      ".",
    ];

    if (e.key >= "0" && e.key <= "9") {
      return;
    }

    if (allowedKeys.includes(e.key)) {
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      blurInput(type);
      handleTimestampBlur(
        key,
        type,
        duration,
        captionsRef.current,
        handleAdjust,
        setValue,
        setDuration
      );
      return;
    }

    e.preventDefault();
  };

  useEffect(() => {
    if (start || end) {
      setDuration({ start: start, end: end });
    }
  }, [caption, caption.start, caption.end, start, end]);

  const onContentChange = useCallback((evt: any) => {
    setCurrentText(evt.target.value);
  }, []);

  const onContentKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLDivElement>) => {
      if (evt.key === "Enter") {
        evt.preventDefault();
        (evt.target as HTMLElement).blur();
      }
    },
    []
  );

  const handleContentBlur = (key: any, content: any) => {
    onContentBlur(key, content, captionsRef.current, setValue);
  };

  const safeValue = typeof currentText === "string" ? currentText : "";

  return (
    <CaptionWrapper
      ref={renderActive(currentPlayerTime, start, end) ? captionRef : null}
      onClick={() => selectCaption(currentPlayerTime, start, end, clickSeek)}
      className={renderActive(currentPlayerTime, start, end) ? "active" : ""}
      /*id={caption.key}*/
    >
      <UpperRow>
        <UpperCol className="left-aligned my-auto">
          <TimestampInput
            type="text"
            placeholder={INPUT_TIME_PLACEHOLDER}
            id="captionStart"
            maxLength={12}
            value={duration.start}
            onChange={(e) =>
              handleTimestampChange(e, "start", duration, setDuration)
            }
            onKeyDown={(e) => handleKeyDown(e, caption.key, "start")}
            onBlur={() =>
              handleTimestampBlur(
                caption.key,
                "start",
                duration,
                captionsRef.current,
                handleAdjust,
                setValue,
                setDuration
              )
            }
            pattern={INPUT_TIME_PATTERN}
            ref={startInputRef}
          />

          <BindingIcon>{getIcon("arrow-right")}</BindingIcon>
          <TimestampInput
            type="text"
            placeholder={INPUT_TIME_PLACEHOLDER}
            id="captionEnd"
            value={duration.end}
            maxLength={12}
            onChange={(e) =>
              handleTimestampChange(e, "end", duration, setDuration)
            }
            onKeyDown={(e) => handleKeyDown(e, caption.key, "end")}
            onBlur={() =>
              handleTimestampBlur(
                caption.key,
                "end",
                duration,
                captionsRef.current,
                handleAdjust,
                setValue,
                setDuration
              )
            }
            pattern={INPUT_TIME_PATTERN}
            ref={endInputRef}
          />
        </UpperCol>
        <UpperCol className="delete right-aligned">
          <Dropdown
            selections={selections}
            title={"Velg handling"}
            toggle={<MenuButton>{getIcon("caption-menu")}</MenuButton>}
            ariaLabel="Velg handling"
            variant="button"
          />
        </UpperCol>
      </UpperRow>
      <ContentEditable
        onChange={onContentChange}
        contentEditable
        html={safeValue}
        onBlur={(evt) => handleContentBlur(caption.key, evt.target.innerHTML)}
        onKeyDown={onContentKeyDown}
        className={`caption-text ${safeValue ? "" : "placeholder"}`}
      />
      <AddButton
        onClick={() => clickNewCaption(captionsRef.current, caption, setValue)}
        className={renderActive(currentPlayerTime, start, end) ? "active" : ""}
      >
        {getIcon("plus")}
      </AddButton>
    </CaptionWrapper>
  );
};

export default forwardRef(MobileCaption);

import { useEffect, useState } from "react";
import {
  PlayerWrapper,
  VideoWrapper,
  Video,
  SubtitleWrapper,
  ActiveSubtitle,
} from "./styled";
import { useVideoEditor } from "../../context";

interface VideoPlayerDesktopProps {
  videoUrl: string;
  activeSubtitle: () => string;
  onTimeUpdate: () => void;
}

const VideoPlayerDesktop: React.FC<VideoPlayerDesktopProps> = ({
  videoUrl,
  activeSubtitle,
  onTimeUpdate,
}) => {
  const { appearance, videoPlayerRef, videoPlayerWidth, isFullscreen } =
    useVideoEditor();
  const [dynamicFontSize, setDynamicFontSize] = useState(appearance?.fontSize);

  useEffect(() => {
    const updateFontSize = () => {
      if (videoPlayerRef.current) {
        const videoHeight = videoPlayerRef.current.clientHeight;
        const userDefinedFontSize = appearance?.fontSize || 16;

        const scaleFactor = videoHeight / 500;
        const calculatedFontSize = userDefinedFontSize * scaleFactor;

        setDynamicFontSize(calculatedFontSize);
      }
    };

    const handleMetadataLoaded = () => {
      updateFontSize();
    };

    if (videoPlayerRef.current) {
      videoPlayerRef.current.addEventListener(
        "loadedmetadata",
        handleMetadataLoaded
      );
    }

    window.addEventListener("resize", updateFontSize);

    updateFontSize();

    return () => {
      if (videoPlayerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        videoPlayerRef.current.removeEventListener(
          "loadedmetadata",
          handleMetadataLoaded
        );
      }
      window.removeEventListener("resize", updateFontSize);
    };
  }, [videoPlayerRef, appearance?.fontSize]);

  return (
    <PlayerWrapper>
      <VideoWrapper isFullscreen={isFullscreen}>
        <Video
          ref={videoPlayerRef}
          src={videoUrl}
          onTimeUpdate={onTimeUpdate}
          preload="metadata"
          playsInline
          controls={false}
        >
          Your browser does not support the video tag.
        </Video>
        <SubtitleWrapper
          className={`vertical-${appearance?.alignment?.vertical}`}
          style={{
            textAlign: appearance?.alignment?.horizontal || "center",
          }}
          playerWidth={videoPlayerWidth}
        >
          <ActiveSubtitle
            fontSize={dynamicFontSize}
            color={appearance?.color}
            backgroundColor={appearance?.backgroundColor}
            fontFamily={appearance?.fontFamily}
            decoration={appearance?.decoration}
            fontStyle={appearance?.fontStyle}
          >
            {activeSubtitle()}
          </ActiveSubtitle>
        </SubtitleWrapper>
      </VideoWrapper>
    </PlayerWrapper>
  );
};

export default VideoPlayerDesktop;

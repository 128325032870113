import { useRef } from "react";
import {
  AccordionWrapper,
  AppearanceAccordion,
  AppearanceWrapper,
  TitleWrapper,
} from "./styled";
import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../../variables";
import PositionSettings from "./position-settings";
import TextSettings from "./text-settings";
import BackgroundSettings from "./background-settings";
import { Title } from "../../../../../library/title";
import { useVideoEditor } from "../context";

const Index = () => {
  const { appearance } = useVideoEditor();
  const appearanceWrapperRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });

  return (
    <>
      {!isMobile && (
        <TitleWrapper>
          <Title size="xs">Generell utforming</Title>
        </TitleWrapper>
      )}
      <AppearanceWrapper
        className={isMobile ? "mobile" : ""}
        ref={appearanceWrapperRef}
      >
        <AccordionWrapper>
          <AppearanceAccordion defaultActiveKey={["0", "1", "2"]}>
            <PositionSettings
              keyRef="0"
              isMobile={isMobile}
              appearance={appearance}
            />
          </AppearanceAccordion>
          <AppearanceAccordion defaultActiveKey="1">
            <TextSettings isMobile={isMobile} keyRef="1" />
          </AppearanceAccordion>
          <AppearanceAccordion defaultActiveKey="2">
            <BackgroundSettings
              isMobile={isMobile}
              appearance={appearance}
              keyRef="2"
            />
          </AppearanceAccordion>
        </AccordionWrapper>
      </AppearanceWrapper>
    </>
  );
};

export default Index;
